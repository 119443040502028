@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

html,
body,
label {
  font-family: "Roboto", sans-serif;
  font-family: Helvetica, Arial, sans-serif;
}

.cuttedBorder1 {
  border-image: linear-gradient(#1f588b 30%, transparent 0 70%, #1f588b 0) 2;
}

.cuttedBorder2 {
  border-image: linear-gradient(#62aaa3 30%, transparent 0 70%, #62aaa3 0) 2;
}

.cuttedBorder3 {
  border-image: linear-gradient(#d49b37 30%, transparent 0 70%, #d49b37 0) 2;
}

.cuttedBorder4 {
  border-image: linear-gradient(#96a669 30%, transparent 0 70%, #96a669 0) 2;
}

.cuttedBorder5 {
  border-image: linear-gradient(#af3f0b 30%, transparent 0 70%, #af3f0b 0) 2;
}

.headingColor1 {
  background-color: linear-gradient(#1f588b 30%, transparent 0 70%, #1f588b 0) 2;
}

.headingColor2 {
  background-color: linear-gradient(#62aaa3 30%, transparent 0 70%, #62aaa3 0) 2;
}

.headingColor3 {
  background-color: linear-gradient(#d49b37 30%, transparent 0 70%, #d49b37 0) 2;
}

.headingColor4 {
  background-color: linear-gradient(#96a669 30%, transparent 0 70%, #96a669 0) 2;
}

.headingColor5 {
  background-color: linear-gradient(#af3f0b 30%, transparent 0 70%, #af3f0b 0) 2;
}

@layer utilities {
  .container-w-h {
    min-height: 90%;
    max-height: 90%;
  }

  .custom-circle {
    @apply bg-gray-700;
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }

  .logo-heading {
    @apply text-sm md:text-xl text-white font-bold;
  }

  .page-heading {
    @apply bg-gray-700 text-white;
  }

  .tab-active {
    @apply bg-indigo-400 text-white;
  }

  .nav-bar-bg {
    @apply bg-gray-700;
  }

  .nav-item {
    @apply text-white text-sm;
  }

  .logo-footer {
    @apply text-sm text-white bg-gray-700 font-bold;
  }

  .nav-dropdown-bg {
    @apply bg-white hover:bg-gray-200 text-xs text-black;
  }

  .bg-theme {
    @apply bg-gray-50;
  }

  .heading {
    @apply text-lg text-white;
  }

  .sub-heading {
    @apply text-xs text-indigo-800 uppercase text-[12px] font-bold tracking-wide;
  }

  .frame {
    @apply border-2;
  }

  .data {
    @apply text-xs;
  }

  .stick-bg {
    @apply bg-gray-700 border border-white text-white text-[13px];
  }

  .input-field {
    @apply text-black h-6 w-5/6;
  }

  .table-header {
    @apply text-sm bg-gray-700 text-white border border-white;
  }

  .table-row {
    @apply bg-slate-200 text-black hover:bg-gray-200 cursor-pointer border border-white text-[12px];
    font-size: 80%;
  }

  .header-Data {
    @apply text-[15px] cursor-pointer border border-white p-1;
    font-weight: 500;
  }

  .table-data {
    @apply border border-white text-[11px];
  }

  .sub-navbar {
    @apply border rounded text-indigo-900 font-bold p-1 text-[12px] h-6;
  }

  .sub-navbar-active {
    @apply bg-gray-700 text-white rounded p-1 text-[12px] h-6;
  }

  .button-border {
    @apply border rounded border-gray-400;
  }

  .pushable {
    border-radius: 12px;
    cursor: pointer;
  }

  .front {
    border-radius: 12px;
    transform: translateY(-6px);
  }

  .pushable:active .front {
    transform: translateY(-2px);
  }
}

input[type="date"]::-ms-clear {
  display: none;
}

input[type="date"]::-webkit-clear-button {
  display: none;
}

.visible {
  display: none;
}

.input-placeholder-dark::placeholder {
  font-weight: bold;
  opacity: 0.8;
  color: black;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.input {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
}

.btn-danger {
  background-color: #dc3545;
  color: #fff;
}

.focus-bg {
  outline-width: thick;
  outline-color: #007bff;
}

input:focus {
  @apply focus-bg;
}

select:focus {
  @apply focus-bg;
}

.Mui-disabled {
  color: black;
}

.css-19bb58m {
  margin: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  color: hsl(0, 0%, 20%);
  box-sizing: border-box;

  visibility: hidden;
}

.css-f20c9h {
  margin: 0;
}

.css-1wy0on6 {
  display: none;
  visibility: hidden;
}

div .css-c5kfpe-control {
  height: 1rem;
  min-height: 1.95rem;
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: 5px;
}

div .css-o25nh7-control {
  height: 1rem;
  min-height: 1.95rem;
}

.cursor-text:hover {
  cursor: text;
}

.css-1xc3v61-indicatorContainer {
  display: none;
}

.bar::-webkit-scrollbar {
  visibility: none;
}

.react-dropdown-select select {
  padding: 0.5rem;
  background-color: red;
}

.e1gn6jc30 span {
}

.react-dropdown-select div {
  display: flex;
  flex-wrap: wrap;
  word-break: break-all;
  word-wrap: break-word;
}
