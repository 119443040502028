/* dateInput.css */
.react-datepicker__month-container {
  background-color: rgb(255, 255, 255);

  z-index: 1000;
}

.react-datepicker-popper {
  z-index: 1000;
}

.react-datepicker-wrapper {
  border: solid 1px rgb(202, 192, 192);
  border-radius: 5px;
}

.react-datepicker {
  z-index: 1;
}

.css-1pysi-MuiFormLabel-root-MuiInputLabel-root {
  line-height: 2.5rem;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

fieldset .css-yjsfm1 {
  padding-bottom: 0.5rem;
}


/* number input */
label[data-shrink="false"]+.MuiInputBase-formControl .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8px;
}

.MuiOutlinedInput-root {
  top: 0;
  align-items: center;
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  max-height: 12px;
}

.css-1wy0on .css-15lsz6c-indicatorContainer {
  display: none;
}

.css-yjsfm1 span {
  display: none;
}

.css-z5e0z-MuiFormLabel-root-MuiInputLabel-root {
  color: black;
  max-height: 12px;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  max-height: 12px;
}

.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input {
  max-height: 14px;
}

.css-ka7ti6-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  position: relative;

  display: flex;
  align-items: center;
  max-height: 28px;
  display: flex;
  align-items: center;
}

.css-1dimb5e-singleValue {
  width: 15rem;
  height: 100%;
  align-items: center;
}

.css-1xc3v61-indicatorContainer .css-tj5bde-Svg {
  padding-right: 0;
  padding-left: 0;
  align-items: center;
  width: 25px;
  color: black;
}

.css-15lsz6c-indicatorContainer .css-tj5bde-Svg {
  padding: 1px;
  padding-right: 0;
  padding-left: 0;
  align-items: center;
  width: 25px;
  color: black;
  background-color: aqua;
  display: none;
}

.css-1u9des2-indicatorSeparator {
  background-color: red;
}

.css-tj5bde-Svg {
  height: 10px;
  padding: 0;
  display: none;
  background-color: red;
  width: 20px;
}

.css-o25nh7-control {
  padding: 1px;
  padding-right: 0;
  padding-left: 0;
  background-color: red;
}

.e1gzf2xs0 {
  min-height: 1rem;
}

.react-dropdown-select {
  max-height: 1rem;
  padding: 0;
  margin: 0;
}

.select {
  min-height: 10rem;
  padding: 0;
  margin: 0;
  font-size: 12px;
}

.css-jznujr-ContentComponent {
  width: 6rem;
  white-space: nowrap;
  /* Prevent text from wrapping to the next line */
  overflow: hidden;
  /* Hide any text that overflows the container */
  text-overflow: ellipsis;
}

.css-z3oh4-DropDown {
  background-color: blue;
}

.react-dropdown-select-dropdown span {
  background-color: blue;
  overflow-y: scroll;
}

.p-dropdown-panel {
  background-color: white;
  border: 2px solid;
  font-size: 14px;
}

.p-dropdown-header {
  background-color: white;
  border: 1px solid;
}

.p-dropdown-trigger-icon {
  height: 10px;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  height: 1.85rem;
}

/* text input */
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  height: 2rem;
}

.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  height: 1.85rem;
}

.css-ka7ti6-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  min-height: 1.75rem;
}

.MuiAutocomplete-hasPopupIcon.css-ka7ti6-MuiAutocomplete-root .MuiOutlinedInput-root .MuiInputBase-sizeSmall {
  height: 1.75rem;
}